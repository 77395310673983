import React, { useState } from 'react';
import { FiPlus } from 'react-icons/fi'; // You can use any icon library
import './FAQ.css'; // Import your CSS file for styling

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: 'What medical services do you provide?',
            answer: 'We offer a comprehensive range of medical services across various departments, including Gynecology, General Surgery, ENT, Dentistry. Visit our services page for more information.',
        },
        {
            question: 'How can I book an appointment?',
            answer: 'You can book an appointment by calling our clinic. Visit our contact page for more information.',
        },
        {
            question: 'What should I bring to my appointment?',
            answer: 'Please bring your previously issued doctor report, a list of your current medications, any relevant medical records, and a list of questions you may have for your doctor.',
        }
    ];

    return (
        <div className="faq-section">
            <div className="underlinef">
                <p></p>
                <h5>FAQ</h5>
            </div>

            <h3>Get Your General Answers</h3>
            <div className="faqInner">
                <div className="questionSection">
                    {faqs.map((faq, index) => (
                        <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
                            <div className="question" onClick={() => toggleAccordion(index)}>
                                <h4>{faq.question}</h4>
                                <FiPlus className={`icon ${activeIndex === index ? 'rotate' : ''}`} />
                            </div>
                            <div className="answer">
                                <p>{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <img src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/655b36a4558d5a88506d158e_about-faq-image.jpg" alt="" />
            </div>
        </div>
    );
};

export default FAQ;