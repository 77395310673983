import React from 'react'
import background from '../../assets/service/service.png'
import './Service.css'
import ENT from '../../assets/Home/Ent.png'
import Maternity from '../../assets/Home/Maternity.png'
import Dentistry from '../../assets/Home/Dentistry.png'
import FAQ from '../FAQ/FAQ'

const Service = () => {
    return (
        <div className='serviceSection'>
            <img className="bgservice" src={background} alt="Background" s />
            <div className="overlay">
                <h1>A history of excellence, a future of innovation</h1>
                <p>For over 32 years, we've been at the forefront of healthcare. Our hospital has always been a place of healing and hope.</p>
            </div>
            <div className="serviceDetails">
                <h4>Our Services</h4>
                <div className="ServiceCard">
                    <div className="card">
                        <div className="cardImg">
                            <img src={ENT} alt="" />
                        </div>
                        <div className="cardBody">
                            <h3>ENT</h3>
                            <div className="points">
                                <p>Micro Surgery Of Year</p>
                                <p>General ENT Surgery</p>
                                <p>FESS</p>
                                <p>Vertigo Clinic</p>
                                <p>Deafness Clinic</p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="cardImg">
                            <img src={Maternity} alt="" />
                        </div>
                        <div className="cardBody">
                            <h3>Maternity</h3>
                            <div className="points">
                                <p>Maternity</p>
                                <p>Gynaecology</p>
                                <p>Antenatal Clinic</p>
                                <p>Labor Room Service</p>
                                <p>Contraception</p>
                                <p>Family Planning Services</p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="cardImg">
                            <img src={Dentistry} alt="" />
                        </div>
                        <div className="cardBody">
                            <h3>Dentistry</h3>
                            <div className="points">
                                <p>Orthodontic Treatment</p>
                                <p>Root Canal Treatment</p>
                                <p>Micro - Endodonticcs</p>
                                <p>Dental Implants</p>
                                <p>Dentures</p>
                                <p>Invisible Braces</p>
                                <p>TMJ Disorders</p>
                                <p>Dental Fillings</p>
                                <p>Extrations</p>
                                <p>Crown & Bridge</p>
                                <p>Teeth Cleaning & Whitening</p>
                                <p>Cosmetic Dentistry</p>
                                <p>Padeatric Dental Care</p>
                                <p>Deriatric Dental care</p>
                                <p>Maxillofacial & Orthognathic Surgery</p>
                                <p>Digital X - Ray</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FAQ/>
        </div>
    )
}

export default Service