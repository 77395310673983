import React from 'react'
import './Home.css'
import HospitalImage from '../../assets/Basics/image.png'
import ENT from '../../assets/Home/Ent.png'
import Maternity from '../../assets/Home/Maternity.png'
import Dentistry from '../../assets/Home/Dentistry.png'
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FAQ from '../FAQ/FAQ'
import { Link } from 'react-router-dom'

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


const DoctorsTeam = [
    {
        profileImg: 'https://assets-global.website-files.com/6555a3eb75f4578c98ad3a01/6555a66c0759d0364d0f2d5d_john-smith.png',
        name: 'Dr. L. S. Rathi',
        title: 'ENT Surgeon',

    },
    {
        profileImg: 'https://assets-global.website-files.com/6555a3eb75f4578c98ad3a01/6555a66c0759d0364d0f2d5d_john-smith.png',
        name: 'Dr. Hemakala L. Rathi',
        title: 'Gynaecologist',

    },
    {
        profileImg: 'https://assets-global.website-files.com/6555a3eb75f4578c98ad3a01/6555a66c0759d0364d0f2d5d_john-smith.png',
        name: 'Dr. Kailash L. Rathi',
        title: 'Orthodontist',

    },
    {
        profileImg: 'https://assets-global.website-files.com/6555a3eb75f4578c98ad3a01/6555a66c0759d0364d0f2d5d_john-smith.png',
        name: 'Dr. Radhika K. Rathi',
        title: 'Endodontist',

    }
]

const testimonials = [
    {
        id: 1,
        name: 'Abhishek Aher',
        location: 'Gulbarga',
        review: 'Visited Rathi’s clinic.Since I  had severe Tooth pain  & consulted Dr. Rathi he advised Rct he done treatment on the very next day.Pain was minimised on the same day.After 3 days I got crown also his receiveing and way of treatment is soooo good thanks to Dr. Rathi Sir and Dr Rathi Ma’am.',
        avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjXCw1dKwLXpUQMUaFPGZPPylecL1gnfVt-Tlk0g8xSW-4wwmhoW=s40-c-rp-mo-br100' // Replace with actual image path
    },
    {
        id: 2,
        name: 'Manij Murkya',
        location: 'Gulbarga',
        review: 'Multi speciality hospital with all the advanced facilities and with best experienced doctors. Also the working staff is well trained and qualified. Hygienic environment of the hospital is also well maintained. Superb !.',
        avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjWJk4trO3CZytpnfLOte9JG4guP_7Z3WKn66dWRNGH18yQY4NU=s40-c-rp-mo-br100' // Replace with actual image path
    },
    {
        id: 3,
        name: 'Shafi Mazhar',
        location: 'Gulbarga',
        review: 'Visited Dr Rathi clinic, very experienced ENT surgeon, his son and daughter in law has brought advanced equipments and techniques for treatment of patients. Thank you..',
        avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjURZI5OBHuzxZHixYzKlOoQgE3G2q5AktsnBdUgp58hHwOidUji=s40-c-rp-mo-ba3-br100' // Replace with actual image path
    },
    {
        id: 4,
        name: 'Manish Jaju',
        location: 'Gulbarga',
        review: 'Thanks to Dr Kailash Rathi and Mrs Dr Kailash rathi for giving me my smile back. He has a great team of family and staff. They are all top of the line professionals, with superb workmanship, artistry and state of the art equipment.',
        avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjUwbmwm9VejflkgGndOrHdSqfHyk88zvSrG9uiuJi8JUiFiegu4=s40-c-rp-mo-br100' // Replace with actual image path
    }
];

const Home = () => {


    return (
        <div className='homeSection'>
            <div className="mainSection">
                <div className="hospitalImg">
                    <img src={HospitalImage} alt="" />
                </div>
                <div className="hospitalInfo">
                    <h2>Welcome to Rathi Hospital</h2>
                    <p>Discover top-notch medical care in Gulbarga. Specializing in ENT, Dentistry, and Gynecology, our expert team provides personalized services for all ages. Experience excellence today!</p>
                </div>
            </div>
            <div className="HaboutSection">
                <div className="imageSec">
                    <img className="img1" src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/65545535fc37e22d79169000_about-image-one.jpg" alt="" />
                    <img className="img2" src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/655461af420b715a6fda302d_highlighter-shape-3.svg" alt="" />
                </div>
                <div className="aboutHospital">
                    <div className="insideAbout">
                        <h5>32+ Years Of Experience</h5>
                        <h2>Your Journey to Better Health Starts Here</h2>
                        <p>Committed to delivering top-tier healthcare services to our community, promoting and enhancing health. Trust us for quality care and a mission-driven approach to wellness.</p>
                        <div className="VisMis">
                            <div className="Vision">
                                <div className="iconVis">
                                    <img src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/65545940083e9d583922deb3_our-mission.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Our Vision</h3>
                                    <p>Our mission is to provide compassionate and high-quality healthcare services.</p>
                                </div>
                            </div>
                            <div className="Mission">
                                <div className="iconMis">
                                    <img src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/65545940420b715a6fd35ff4_our-vission.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Our Mission</h3>
                                    <p>Our vision is to be a trusted partner in the health and happiness of the community</p>
                                </div>
                            </div>
                        </div>
                        <div className="butnAbout">
                            <Link to='/about'>
                                <button>
                                    More About Us
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="specializedCare">
                <div className="headingSpecialized">
                    <h2>Our Specialized Care</h2>
                </div>
                <div className="cardSection">
                    <div className="careCard">
                        <img src={ENT} alt="" />
                        <h4>Ear, Nose, Throat (ENT)</h4>
                        <p>ENT specialists treat conditions of the ears, nose, and throat, including hearing, sinusitis, and tonsillitis</p>
                    </div>
                    <div className="careCard">
                        <img src={Maternity} alt="" />
                        <h4>Maternity Services</h4>
                        <p>Our maternity services include prenatal care, labor and delivery, and postpartum care</p>
                    </div>
                    <div className="careCard">
                        <img src={Dentistry} alt="" />
                        <h4>Dentistry</h4>
                        <p>Dentists provide preventive, restorative and cosmetic dental treatments forpatients of all ages.</p>
                    </div>
                </div>
            </div>
            <div className="ourTeam">
                <img className="img1" src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/655842348206bf9a75deac1a_highlighter-shape-7.svg" alt="" />
                <h4>Team Members</h4>
                <h2>Our Expert Doctors</h2>
                <div className="mainforflex">
                    {
                        DoctorsTeam.map(({ profileImg, name, title }) => {
                            return (

                                <div className="teamMember">
                                    <img src={profileImg} alt="" />
                                    <h3>{name}</h3>
                                    <p>{title}</p>
                                </div>

                            )
                        })
                    }
                </div>
                <img className="img2" src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/65584234b0b969bffb755e1b_highlighter-shape-8.svg" alt="" />
            </div>
            <div className="chooseSection">
                <div className="leftSide">
                    <h4>Why Choose Us</h4>
                    <h2>In Sickness and in Health, We're Here for You</h2>
                    <p>We take a holistic approach to care, emphasizing preventive measures, early detection, and personalized treatment plans</p>
                    <div className="pointsDiv">
                        <div className="point" >
                            <LooksOneRoundedIcon />
                            <div className="content">
                                <h3>Experienced Professionals</h3>
                                <p>Our team of dedicated healthcare providers has years of experience and expertise.</p>
                            </div>
                        </div>
                        <div className="point" >
                            <LooksTwoRoundedIcon />
                            <div className="content">
                                <h3>Compassionate Care</h3>
                                <p>Compassionate care is vital aspect of ensuring the well-being and comfort of patients.</p>
                            </div>
                        </div>
                        <div className="point" >
                            <Looks3RoundedIcon />
                            <div className="content">
                                <h3>Patient Centered</h3>
                                <p>Your health is our top priority. We work closely with each patient to develop healthcare plans.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightSide">
                    <img src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/65548fe4ec32b877319d31ce_why-choose-us.jpg" alt="" />
                </div>
            </div>
            <div className="testimonialSection">
                <Carousel responsive={responsive} ssr={true} infinite={true} autoPlaySpeed={3000} keyBoardControl={true} customTransition="all .5" transitionDuration={500} autoplay="true" data-easing="ease" style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="review-slide">
                            <div className="review">
                                <img src={testimonial.avatar} alt={testimonial.name} className="review-avatar" />
                                <div className="review-content">
                                    <h3 className="review-name">{testimonial.name}</h3>
                                    <p className="review-location">{testimonial.location}</p>
                                    <blockquote className="review-text">{testimonial.review}</blockquote>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            <FAQ />
        </div>

    )
}

export default Home