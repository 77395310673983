import React from 'react'
import './About.css'
import background from '../../assets/Basics/aboutmain.png'
import img1 from '../../assets/About/1.png'
import img2 from '../../assets/About/2.png'
import img3 from '../../assets/About/3.png'
import img4 from '../../assets/About/4.png'
import img5 from '../../assets/About/5.png'
import img6 from '../../assets/About/6.png'
import FAQ from '../FAQ/FAQ'

const valuesCard = [
    {
        id: 1,
        title: 'Compassion',
        description: 'We treat everyone with kindness and empathy.',
        icon: img1, // Removed curly braces
    },
    {
        id: 2,
        title: 'Respect',
        description: 'We honor the dignity of every person.',
        icon: img2, // Removed curly braces
    },
    {
        id: 3,
        title: 'Learning',
        description: 'We are committed to lifelong learning',
        icon: img3, // Removed curly braces
    },
    {
        id: 4,
        title: 'Innovation',
        description: 'We seek new ideas and solutions',
        icon: img4, // Removed curly braces
    },
    {
        id: 5,
        title: 'Quality',
        description: 'We deliver care that is safe, effective and timely',
        icon: img5, // Removed curly braces
    },
    {
        id: 6,
        title: 'Teamwork',
        description: 'We collaborate to achieve our mission',
        icon: img6, // Removed curly braces
    },
];

const About = () => {
    return (
        <div className="section">
            <img className="bgnews" src={background} alt="Background"  />
            <div className="overlay">
                <h1>A history of excellence, a future of innovation</h1>
                <p>For over 32 years, we've led healthcare innovation, offering healing and hope. Trust our hospital's legacy for top-tier care and a beacon of wellness.</p>
            </div>
            <div className="mission">
                <h4>Our Mission</h4>
                <p>To provide the best care to every patient every day through integrated clinical practice, education and research.</p>
            </div>
            <div className="vision">
                <h4>Our Vision</h4>
                <p>To be the leader in innovative, high-value healthcare for all patients.</p>
            </div>
            <div className="values">
                <h4>Our Values</h4>
                <div className="value-card">
                    {valuesCard.map(value => (
                        <div key={value.id} className="value-card-item">
                            <img src={value.icon} alt={value.title} />
                            <h3>{value.title}</h3>
                            <p>{value.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <FAQ/>
        </div>
    )
}

export default About
