import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Navigation.css';
import Logo from '../../assets/Basics/logo.png';
import { Link } from 'react-router-dom';

const Navigation = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleDropdownItemClick = (path) => {
    setExpanded(false); // Close the navbar collapse when a dropdown item is clicked
    navigate(path);
  };

  return (
    <div className='navSec'>
      <Navbar expand="lg" className="bg-body-tertiary" expanded={expanded}>
        <Container>
          <div className="LogoSection">
            <Navbar.Brand href="/"><img src={Logo} alt='logo' /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} className="navItem" to="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
              <Nav.Link as={Link} className="navItem" to="/about" onClick={() => setExpanded(false)}>About</Nav.Link>
              <Nav.Link as={Link} className="navItem" to="/services" onClick={() => setExpanded(false)}>Services</Nav.Link>
              <Nav.Link as={Link} className="navItem" to="/team" onClick={() => setExpanded(false)}>Our Team</Nav.Link>
              <Nav.Link as={Link} className="navItem" to="/news" onClick={() => setExpanded(false)}>News</Nav.Link>
              <Nav.Link as={Link} className="navItem" to="/gallery" onClick={() => setExpanded(false)}>Gallery</Nav.Link>
              <Nav.Link as={Link} className="navItem" to='/contact' onClick={() => setExpanded(false)}>Contact US</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navigation;
