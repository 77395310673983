import React from "react";
import "./News.css";
import img1 from "../../assets/News/11.png";
import img2 from "../../assets/News/22.png";
import img13 from "../../assets/News/33.png";
import img14 from "../../assets/News/44.png";
import img15 from "../../assets/News/55.png";
import FAQ from "../FAQ/FAQ";

export default function News() {
  return (
    <div className="NewsSection">
      <div className="trending">
        <p>
          <h2>Trending</h2>
        </p>
      </div>

      <div className="text">
        <div className="news1">
          <img src={img1}></img>
          <div className="textone">
            <h4>The pandemic has exposed the fallacy of 'wellness'</h4>
            <p>
              Opinion: The wellness industry is a $4.5 trillion market that has
              been thriving for years, but the pandemic has exposed its failings.
            </p>
          </div>
        </div>

        <div className="news2">
          <img src={img2}></img>
          <div className="textone">
            <h4>How to stay healthy as you age</h4>
            <p>
              As we age, our bodies change and so do our health needs. Here's
              what you can do to maintain your health and well-being as you get
              older.
            </p>
          </div>
        </div>

        <div className="news3">
          <img src={img13}></img>
          <div className="textone">
            <h4>The science behind the latest diet trends</h4>
            <p>
              From keto to intermittent fasting, there's no shortage of trendy
              diets that promise to help you lose weight and improve your
              health. But do they really work? We asked the experts to find out.
            </p>
          </div>
        </div>

        <div className="news4">
          <img src={img14}></img>
          <div className="textone">
            <h4>How to talk to your kids about mental health</h4>
            <p>
              Mental health is an important topic that affects people of all
              ages, including children. Here are some tips on how to talk to
              your kids about mental health in a way that is age-appropriate and
              supportive.
            </p>
          </div>
        </div>

        <div className="news5">
          <img src={img15}></img>
          <div className="textone">
            <h4>The future of healthcare: What to expect in the next decade</h4>
            <p>
              Technology is revolutionizing the way we access and receive
              healthcare. Here's a look at some of the most exciting healthcare
              innovations and trends that are expected to shape the industry in
              the next 10 years.
            </p>
          </div>
        </div>
      </div>
      <FAQ/>
    </div>
  );
}
