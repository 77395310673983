import React from 'react'
import './Gallery.css'
import img5 from '../../assets/News/11.png'
import img1 from '../../assets/News/22.png'
import img2 from '../../assets/News/33.png'
import img3 from '../../assets/News/44.png'
import img4 from '../../assets/News/55.png'
import FAQ from '../FAQ/FAQ'

const Gallery = () => {
    return (
        <div className='gallerySection'>
            <div className="topSection">
                <div className="underline">
                    <p></p>
                    <h5>Gallery</h5>
                </div>
                <div className="gratitude">
                    <h3>Picturesque Insights Into Our Patient Experience</h3>

                </div>
                <img className="img1" src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/655b135120eb4c16ccf8b1f1_highlighter-shape-11.svg" alt="" />
                <img className="img2" src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/6558423457c432bd2be26543_highlighter-shape-4.svg" alt="" />
            </div>
            <div className='gallerySection'>
                <div className='main-heading'>Gallery</div>
                <div className='img-section grid'>
                    <img className='img' alt='h0' src={img1} />
                    <img className='img' alt='h1' src={img2} />
                    <img className='img' alt='h2' src={img3} />
                    <img className='img' alt='h3' src={img4} />
                    <img className='img' alt='h4' src={img5} />
                    {/* <img className='img' alt='h5' src={img6} />
                    <img className='img' alt='h6' src={img7} />
                    <img className='img' alt='h7' src={img8} />
                    <img className='img' alt='h8' src={img9} />
                    <img className='img' alt='h9' src={img10} /> */}


                </div>
            </div>
            <FAQ/>
        </div>
    )
}

export default Gallery