import React from 'react'
import './Team.css'
import FAQ from '../FAQ/FAQ'


const DoctorsTeam = [
  {
    profileImg: 'https://assets-global.website-files.com/6555a3eb75f4578c98ad3a01/6555a66c0759d0364d0f2d5d_john-smith.png',
    name: 'Dr. John Doe',
    title: 'ENT Specialist',

  },
  {
    profileImg: 'https://assets-global.website-files.com/6555a3eb75f4578c98ad3a01/6555a66c0759d0364d0f2d5d_john-smith.png',
    name: 'Dr. lalit ',
    title: 'ENT Specialist',

  },
  {
    profileImg: 'https://assets-global.website-files.com/6555a3eb75f4578c98ad3a01/6555a66c0759d0364d0f2d5d_john-smith.png',
    name: 'Dr. kejriwal',
    title: 'ENT Specialist',

  },
  {
    profileImg: 'https://assets-global.website-files.com/6555a3eb75f4578c98ad3a01/6555a66c0759d0364d0f2d5d_john-smith.png',
    name: 'Dr. Modi',
    title: 'ENT Specialist',

  }
]

const Team = () => {
  return (
    <div className='ourTeamSection'>
      <div className="topSection">
        <div className="underline">
          <p></p>
          <h5>Expert Details</h5>
        </div>
        <div className="gratitude">
          <h3>Prescribing Gratitude: Honoring Our Devoted Doctors</h3>

        </div>
        <img className="img1" src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/655b135120eb4c16ccf8b1f1_highlighter-shape-11.svg" alt="" />
        <img className="img2" src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/6558423457c432bd2be26543_highlighter-shape-4.svg" alt="" />
      </div>
      <div className="ourTeam">
        <h4>Team Members</h4>
        <h2>Our Expert Doctors</h2>
        <div className="mainforflex">
          {
            DoctorsTeam.map(({ profileImg, name, title }) => {
              return (

                <div className="teamMember">
                  <img src={profileImg} alt="" />
                  <h3>{name}</h3>
                  <p>{title}</p>
                </div>

              )
            })
          }
        </div>
      </div>
      <FAQ />

    </div>

  )
}

export default Team