import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigationbar from './components/Navbar/Navigation';
import Home from './components/Home/Home';
import OurTeam from './components/Our Team/Team';
import About from './components/About/About';

import { Footer } from "./components/Footer/Footer";
import News from "./components/News/News";
import Service from './components/Service/Service';
import Gallery from './components/Gallery/Gallery';
import ContactForm from './components/Contact/Contact';

function App() {
  return (
    <div className="App">
      <Router>
        <Navigationbar />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Home />} />
          <Route path="/team" element={<OurTeam />} />
          <Route path="/news" element={<News />} />
          <Route path="/services" element={<Service/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/contact" element={<ContactForm/>} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}
export default App;
