import React from "react";
import logo from "../../assets/Basics/logo.png";
import { SocialIcon } from "react-social-icons";
import "./Footer.css";
import { Link } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';


export const Footer = () => {
  return (
    <div className="footerSection">
      <div className="SectionOne">
        <img className="img" src={logo} alt="logo" />
        <div className="contact">
          <p>Call Us</p>
          <p>9380923092</p>
          <p>08472223321</p>
        </div>

        <div className="address">
          <p>Reach Us</p>
          <p>
            1-885/2f - Hemkunj, Old Jewargi Road, Kalaburagi, Karnataka 585102
          </p>
        </div>

        <div className="Timings">
          <p>Open Hours</p>
          <p>Mon-Sat 10:30-20:00</p>
          <p>Sun-Closed</p>
        </div>

        <div className="Icons">
          <p>Follow Us</p>
          <div className="socialIcons">
          <SocialIcon url="https://www.facebook.com/rathihospitalklbg/" />
          <SocialIcon url="https://www.instagram.com/rathi.hospital/" />
          <a href="https://wa.me/9380923092" target="_blank" rel="noopener noreferrer">
            <i  className="fab fa-whatsapp" style={{ fontSize: '50px',width: '50px', height: '50px', color: 'green' }}></i>
          </a>
          </div>
        </div>
      </div>

      <div className="SectionTwo">
        <div className="copyright">
          <p>Copyright 2024 Rathi Hospital</p>
        </div>

        <div className="BT">
          <p>
            powered by <Link to='https://belageri.com/'> <span>Belageri technologies</span></Link>
          </p>
        </div>
      </div>
    </div>
  );
};
