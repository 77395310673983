import React, { useState } from 'react';
import { FiUser, FiMail, FiMessageCircle } from 'react-icons/fi'; // Import icons from react-icons library
import doctorPatientImage from '../../assets/News/55.png'; // Replace with your actual image path
import './Contact.css';
import emailjs from 'emailjs-com';



const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Send email using SMTPJS
    emailjs.sendForm('service_u9fth7s', 'template_ocxi2lm', e.target, 'lxwhJ2uTk1PFZg802')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        // Optionally, reset the form data after successful submission
        setFormData({ name: '', email: '', message: '' });
        console.log("Message sent successfully!");
      }, (error) => {
        console.error('Error sending email:', error.text);
      });
  };

  return (
    <div className="contactSection">
      <div className="topSection">
        <div className="underline">
          <p></p>
          <h5>Contact Us</h5>
        </div>
        <div className="gratitude">
          <h3>Contact us now for appointment and general inquiries.</h3>

        </div>
        <img className="img1" src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/655b135120eb4c16ccf8b1f1_highlighter-shape-11.svg" alt="" />
        <img className="img2" src="https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/6558423457c432bd2be26543_highlighter-shape-4.svg" alt="" />
      </div>
      <div className="contact-form">
        <img src={doctorPatientImage} alt="Doctor and Patient" className="doctor-patient-image" />

        <div className="mainForm">
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <FiUser className="icons" />
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="input-group">
              <FiMail className="icons" />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="input-group">
              <FiMessageCircle className="icons" />
              <textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                rows={4}
              />
            </div>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div >
  );
};

export default ContactForm;
